var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base" },
    [
      _c("div", { staticClass: "card-title" }, [_vm._v("报修描述")]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(0),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.dataObj.title,
                expression: "dataObj.title"
              }
            ],
            staticClass: "cell-content",
            attrs: { placeholder: "请输入设备名称", maxlength: "20" },
            domProps: { value: _vm.dataObj.title },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.dataObj, "title", $event.target.value)
              }
            }
          })
        ]),
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(1),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.dataObj.position,
                expression: "dataObj.position"
              }
            ],
            staticClass: "cell-content",
            attrs: { placeholder: "请输入报修位置", maxlength: "50" },
            domProps: { value: _vm.dataObj.position },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.dataObj, "position", $event.target.value)
              }
            }
          })
        ]),
        _c("div", { staticClass: "card-cell textarea-cell" }, [
          _vm._m(2),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.dataObj.details,
                expression: "dataObj.details"
              }
            ],
            staticClass: "cell-content",
            attrs: {
              placeholder: "请输入详情描述",
              maxlength: "300",
              rows: "4"
            },
            domProps: { value: _vm.dataObj.details },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.dataObj, "details", $event.target.value)
              }
            }
          })
        ]),
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(3),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.dataObj.contacts,
                expression: "dataObj.contacts"
              }
            ],
            staticClass: "cell-content",
            attrs: { placeholder: "请输入联系人姓名", maxlength: "20" },
            domProps: { value: _vm.dataObj.contacts },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.dataObj, "contacts", $event.target.value)
              }
            }
          })
        ]),
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(4),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.dataObj.contactsMobile,
                expression: "dataObj.contactsMobile"
              }
            ],
            staticClass: "cell-content",
            attrs: { placeholder: "请输入联系电话", maxlength: "11" },
            domProps: { value: _vm.dataObj.contactsMobile },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.dataObj, "contactsMobile", $event.target.value)
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "card-title" }, [_vm._v("现场照片")]),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-cell imgs-cell" },
          [
            _vm._l(_vm.fileList, function(item, index) {
              return _c("div", { key: index, staticClass: "up-img-cell" }, [
                _c("img", {
                  staticClass: "up-img",
                  attrs: { src: _vm.imgUrl(item), alt: "" },
                  on: {
                    click: function($event) {
                      return _vm.showImg(item)
                    }
                  }
                }),
                _c("img", {
                  staticClass: "remove-img",
                  attrs: {
                    src: require("../../../assets/img/enterprise/remove.png"),
                    alt: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.onRemoveFile(index)
                    }
                  }
                })
              ])
            }),
            _vm.fileList.length <= 2
              ? _c(
                  "div",
                  { staticClass: "up-img-cell" },
                  [_c("upload-img", { on: { sendOrgImg: _vm.getOrgImg } })],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "but-cell" },
        [
          _c(
            "mt-button",
            {
              staticClass: "save-but",
              attrs: { loading: _vm.submiting, type: "primary" },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c(
        "mt-popup",
        {
          model: {
            value: _vm.showImgFlag,
            callback: function($$v) {
              _vm.showImgFlag = $$v
            },
            expression: "showImgFlag"
          }
        },
        [_c("img", { staticClass: "big-img", attrs: { src: _vm.tempImgUrl } })]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("设备名称")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("报修位置")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("详情描述")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("报修联系人")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("联系电话")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }